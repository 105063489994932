import index from "./content/index.yaml";
import {v4 as uuidv4} from "uuid";
import {
    createHrefUrl,
    getAllQueryParamsFromString,
    getCookie,
    getWindowSearchParams,
    setCookie
} from "./src/utils/utils";

export const onClientEntry = async () => {
    const uuid = await getCookie("journey_uuid");
    if (uuid === "") {
        await setCookie("journey_uuid", uuidv4(), 365);
    }

    const loggedIn = await getCookie("logged_in");
    if (loggedIn !== "") {
        window.location.href = createHrefUrl(index.navbar.href, {...getAllQueryParamsFromString(getWindowSearchParams()), ...{journey_uuid: getCookie("journey_uuid")}})
    }
};

export const onInitialClientRender = async () => {
    let navbarLinks = document.getElementsByClassName("navbar-link")
    Array.from(navbarLinks).forEach((link) => {
        link.href = createHrefUrl(`/`, {...getAllQueryParamsFromString(getWindowSearchParams())}) + `#${link.innerText.toLowerCase().replace(/ /g,"-").replaceAll("?","")}`
    })

    let playNowLinks = document.getElementsByClassName("play-now-link")
    Array.from(playNowLinks).forEach((link) => {
        link.href = createHrefUrl(link.href, {...getAllQueryParamsFromString(getWindowSearchParams()), ...{journey_uuid: getCookie("journey_uuid")}})
    })

    let basicLinks = document.getElementsByClassName("basic-link")
    Array.from(basicLinks).forEach((link) => {
        link.href = createHrefUrl(link.href, {...getAllQueryParamsFromString(getWindowSearchParams())})
    })
}